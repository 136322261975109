import type { MaybeRef } from 'vue'
import { cloneFnJSON } from '@vueuse/core'
import type { ISearchPropertyLeasePortfolioRequest } from '@portfolio'

export const usePortfolioFilterCount = (
  filters: MaybeRef<ISearchPropertyLeasePortfolioRequest>,
) => {
  const parsed = computed(() => {
    const parsed = parseFilters(cloneFnJSON(unref(filters)))

    delete parsed.sort
    delete parsed.groupBy
    delete parsed.direction
    delete parsed.productScope

    return parsed
  })
  const count = computed(() => Object.keys(parsed.value).length)

  return { count }
}
